import React from "react";

import IntroBlock from "../IntroBlock";
import BlockWrapper from "../BlockWrapper";

const ActionBlock = ({ content, shade, children, ...props }) => {
  if (!content) {
    return null;
  }

  return (
    <BlockWrapper
      flexDirection="column"
      py={["50px", "70px", "100px"]}
      {...props}
    >
      <IntroBlock shade={shade} content={content} children={children} />
    </BlockWrapper>
  );
};

export default ActionBlock;
