import React from "react";

import { markdownify } from "config/utils";

import Button from "components/Button";

import { Wrapper, Title, Heading, Subheading } from "./styles";

const IntroBlock = ({ content, shade, children, ...props }) => {
  return (
    <Wrapper {...props}>
      {content.title && <Title>{content.title}</Title>}
      <Heading shade={shade}>{content.heading}</Heading>
      {content.subheading && (
        <Subheading
          shade={shade}
          dangerouslySetInnerHTML={{ __html: markdownify(content.subheading) }}
        />
      )}
      {content.buttonText && content.buttonPath && (
        <Button mt="30px" to={content.buttonPath} shade="primary">
          {content.buttonText}
        </Button>
      )}
      {children}
    </Wrapper>
  );
};

export default IntroBlock;
