import styled from "styled-components";
import {
  color,
  space,
  width,
  borders,
  zIndex,
  maxWidth,
  overflow
} from "styled-system";
import { transparentize, lighten } from "polished";
import styledMap from "styled-map";

import theme from "config/theme";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  width: calc(100% - 40px);
  margin: auto;
  z-index: 1;
  padding: 30px 0;
  border-radius: ${theme.noradius};
  max-width: ${theme.size.maxWidth};

  background-color: ${styledMap("shade", {
    paper: theme.color.paper2,
    dark: theme.color.gray,
    medium: lighten(0.03, theme.color.gray),
    lightgray: theme.color.gray94,
    light: "white",
    default: "transparent"
  })};

  box-shadow: ${styledMap("shade", {
    dark: `0 3px 25px 0 ${transparentize(0.78, theme.color.gray)}`,
    medium: `0 3px 25px 0 ${transparentize(0.88, "black")}`,
    light: `0 3px 25px 0 ${transparentize(0.92, theme.color.gray2)}`,
    default: "none"
  })};

  ${color};
  ${space};
  ${width};
  ${zIndex};
  ${borders};
  ${overflow};
  ${maxWidth};

  @media (max-width: ${theme.size.desktop}) {
    padding: 50px 10px;

    ${space};
  }

  @media (max-width: ${theme.size.tablet}) {
    width: calc(100% - 20px);

    ${width};
    ${space};
  }

  @media (max-width: ${theme.size.mobile}) {
    width: 100%;
    padding: 50px 0;
    border-radius: 0;

    ${width};
    ${space};
  }
`;
