import styled from "styled-components";
import styledMap from "styled-map";
import { space, order, width, textAlign } from "styled-system";

import theme from "config/theme";

import { H3, H6 } from "components/Text";
import StyledImage from "components/Image";

export const Block = styled.div`
  ${width};
  ${space};

  @media (min-width: ${theme.size.tablet}) {
    ${order};
  }
`;

export const Title = styled(H6)`
  margin-bottom: 20px;
  color: ${theme.color.primary};
  font-weight: ${theme.font.weight.regular};
`;

export const Heading = styled(H3)`
  color: ${props => (props.shade === "light" ? "white" : theme.color.gray)};

  font-size: ${styledMap("size", {
    large: theme.font.size.alpha,
    default: ""
  })};

  @media (max-width: ${theme.size.mobile}) {
    font-size: ${styledMap("size", {
      large: theme.font.size.cesar,
      default: ""
    })};
  }

  ${textAlign};
`;

export const Content = styled.div`
  width: 100%;
  line-height: 1.7;
  padding: 5px 0;
  color: ${props =>
    props.shade === "light" ? theme.color.paper2 : theme.color.gray2};

  font-size: ${styledMap("size", {
    large: theme.font.size.upsilon,
    default: ""
  })};

  @media (max-width: ${theme.size.mobile}) {
    font-size: ${styledMap("size", {
      large: theme.font.size.delta,
      default: theme.font.size.epsilon
    })};
  }

  ul {
    margin-bottom: 0;
  }

  li {
    font-size: ${theme.font.size.epsilon};
  }

  ${space};
`;

export const Image = styled(StyledImage)`
  display: block;
  opacity: 0.85;
  border-radius: 50%;
  width: 100%;

  margin: ${styledMap("flip", {
    true: "0 110px 0 auto",
    false: "0 auto 0 110px"
  })};

  max-width: ${styledMap("size", {
    large: "90%",
    small: `54%`,
    default: "68%"
  })};

  @media (max-width: ${theme.size.desktop}) {
    max-width: ${styledMap("size", {
      small: `300px`,
      default: "calc(100% - 100px)"
    })};
  }

  @media (max-width: ${theme.size.tablet}) {
    margin: 0 auto 30px;

    max-width: ${styledMap("size", {
      small: `250px`,
      default: "400px"
    })};
  }

  @media (max-width: ${theme.size.mobile}) {
    max-width: ${styledMap("size", {
      small: `250px`,
      default: "300px"
    })};
  }
`;
