import styled from "styled-components";
import { transparentize } from "polished";

import theme from "config/theme";

import Button from "components/Button";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 13;
  -webkit-overflow-scrolling: touch;
`;

export const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  max-width: 1400px;
  max-height: 100%;
  z-index: 12;
  overflow: hidden;
  border-radius: ${theme.radius};
  box-shadow: 0 3px 45px 0 ${transparentize(0.85, "black")};
  opacity: ${props => (props.isVisible ? "1" : "0")};
  transition: 0.4s 0.3s;
`;

export const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 11;
  top: 0;
  left: 0;
  background-color: ${transparentize(0.1, theme.color.gray)};
  opacity: ${props => (props.isVisible ? "1" : "0")};
  transition: 0.4s;
`;

export const CloseButton = styled(Button)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 0;
  border: none;
  z-index: 20;
  background-color: transparent;
  box-shadow: none;

  &:hover {
    opacity: 0.7;
    box-shadow: none;
    background-color: transparent;
  }

  &:active {
    transform: scale(0.9);
  }
`;
