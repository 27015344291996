import React from "react";

import { markdownify } from "config/utils";

import Button from "components/Button";
import { BlockWrapper } from "components/Block";

import { Image, Title, Heading, Content, Block } from "./styles";

const ContentBlock = ({
  content,
  flip,
  shade,
  image,
  size,
  button,
  imageSize,
  alignItems,
  contentShade,
  ...props
}) => {
  if (image || content.image) {
    return (
      <BlockWrapper
        shade={shade}
        alignItems={alignItems || "center"}
        {...props}
      >
        <Block width={["100%", "100%", "50%"]} order={flip ? "0" : "2"}>
          {image || (
            <Image content={content.image} flip={flip} size={imageSize} />
          )}
        </Block>
        <Block px={["0", "10px", "20px"]} width={["100%", "100%", "50%"]}>
          {content.title && <Title>{content.title}</Title>}
          <Heading size={size} shade={contentShade}>
            {content.heading}
          </Heading>
          <Content
            size={size}
            shade={contentShade}
            dangerouslySetInnerHTML={{ __html: markdownify(content.text) }}
          />
          {button ||
            (content.buttonText && content.buttonPath && (
              <Button mt="20px" to={content.buttonPath} shade="primary">
                {content.buttonText}
              </Button>
            ))}
        </Block>
      </BlockWrapper>
    );
  }

  return (
    <BlockWrapper
      shade={shade}
      pt={["50px", "65px"]}
      pb={["40px", "50px"]}
      {...props}
    >
      <Block
        width={["100%", "100%", "40%", "30%"]}
        pt={["0", "0", "5px", "5px"]}
        pl={["0", "0", "0", "20px"]}
        pr={["0", "0", "40px", "60px"]}
      >
        {content.title && <Title>{content.title}</Title>}
        <Heading shade={contentShade}>{content.heading}</Heading>
      </Block>
      <Block
        width={["100%", "100%", "60%", "70%"]}
        pr={["0", "0", "0", "40px"]}
        pt={["0", content.title ? "40px" : "0"]}
      >
        <Content
          dangerouslySetInnerHTML={{ __html: markdownify(content.text) }}
          shade={contentShade}
        />
      </Block>
    </BlockWrapper>
  );
};

export default ContentBlock;
