import styled from "styled-components";
import { height } from "styled-system";

import theme from "config/theme";

import Button from "components/Button";

export const Wrapper = styled.div`
  position: relative;
  display: block;
  height: 620px;
  width: 100%;
  overflow: hidden;
  background-color: ${theme.color.gray2};

  ${height};

  @media (max-width: ${theme.size.tablet}) {
    height: 550px;

    ${height};
  }

  @media (max-width: ${theme.size.mobile}) {
    height: 450px;

    ${height};
  }
`;

export const PlayButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0;
  border: none;
  z-index: 2;
  transform-origin: left;

  &:active {
    opacity: 0.8;
    transform: translate(-50%, -50%) scale(0.96);
  }
`;
