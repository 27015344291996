import React, { Component } from "react";

import Icon from "components/Icon";

import { Wrapper, Inner, Overlay, CloseButton } from "./styles";

import YouTube from "./YouTube";

class VideoModal extends Component {
  state = {
    isVisible: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: true
      });
    }, 200);
  }

  render() {
    const { isVisible } = this.state;
    const { toggleVisibility, videoId } = this.props;

    return (
      <>
        <Wrapper isVisible={isVisible}>
          <CloseButton onClick={toggleVisibility}>
            <Icon icon="cross" size="18px" />
          </CloseButton>
          <Inner isVisible={isVisible}>
            <YouTube id={videoId} />
          </Inner>
          <Overlay isVisible={isVisible} onClick={toggleVisibility} />
        </Wrapper>
      </>
    );
  }
}

export default VideoModal;
