import React from "react";

import { Wrapper } from "./styles";

const YouTube = ({ id, loop, start }) => {
  const loopSetting = `&loop=${loop ? `1&playlist=${id}` : "0"}`;
  const startSetting = `&start=${start || "0"}`;

  const settings = loopSetting + startSetting;

  return (
    <Wrapper>
      <iframe
        title={id}
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${id}?autoplay=1&controls=0&fs=0&iv_load_policy=3&modestbranding=1&showinfo=0&playsinline=1&rel=0&mute=1&suggestedQuality=hd1080${settings}`}
        frameBorder="0"
      />
    </Wrapper>
  );
};

export default YouTube;
