import React, { Component } from "react";

import Icon from "components/Icon";

import { Wrapper, PlayButton } from "./styles";

import Preview from "./Preview";
import VideoModal from "components/VideoModal";

class VideoBlock extends Component {
  state = {
    modalVisible: false
  };

  toggleModalVisibility = () => {
    this.setState(state => ({ modalVisible: !state.modalVisible }));
  };

  render() {
    const { modalVisible } = this.state;
    const { loop, start, alignButtonTop, content, ...props } = this.props;

    if (!content.youtube || !content.youtube.mainId) {
      return null;
    }

    return (
      <>
        <Wrapper {...props}>
          <PlayButton
            mt={alignButtonTop}
            onClick={this.toggleModalVisibility}
            shade="primary"
          >
            <Icon icon="play" color="white" size="35px" />
          </PlayButton>
          <Preview loop={loop} start={start} id={content.youtube.previewId} />
        </Wrapper>
        {modalVisible && (
          <VideoModal
            videoId={content.youtube.mainId}
            toggleVisibility={this.toggleModalVisibility}
          />
        )}
      </>
    );
  }
}

export default VideoBlock;
