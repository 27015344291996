import styled from "styled-components";
import { transparentize } from "polished";

import theme from "config/theme";

export const Wrapper = styled.div`
  display: block;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  &::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${transparentize(0.6, theme.color.gray)};
  }

  iframe,
  object,
  embed {
    position: absolute;
    top: -40%;
    left: -40%;
    width: 180%;
    height: 180%;

    @media (min-width: 1400px) {
      top: -100%;
      left: -100%;
      width: 300%;
      height: 300%;
    }

    @media (max-width: ${theme.size.mobile}) {
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
    }
  }
`;
