import styled from "styled-components";
import { space, textAlign } from "styled-system";

import theme from "config/theme";

import { H3 } from "components/Text";

export const Wrapper = styled.div`
  position: relative;
  margin: auto;
  max-width: 750px;
  text-align: center;
  width: 100%;

  ${space};
  ${textAlign};
`;

export const Title = styled(H3)`
  margin-bottom: 20px;
  color: ${theme.color.gray};
  font-weight: ${theme.font.weight.regular};
`;

export const Heading = styled(H3)`
  width: 100%;
  margin-bottom: 20px;
  color: ${props => (props.shade === "light" ? "white" : theme.color.gray)};
`;

export const Subheading = styled.div`
  width: 100%;
  margin: 0 auto 10px;
  font-size: ${theme.font.size.upsilon};
  color: ${props =>
    props.shade === "light" ? theme.color.paper2 : theme.color.gray2};

  @media (max-width: ${theme.size.mobile}) {
    font-size: ${theme.font.size.delta};
  }

  p {
    margin: 0;
  }
`;
