import React from "react";

import Grid, { Container } from "components/Grid";

import { Wrapper } from "./styles";

const BlockWrapper = ({ alignItems, children, ...props }) => {
  return (
    <Wrapper {...props}>
      <Grid>
        <Container alignItems={alignItems}>{children}</Container>
      </Grid>
    </Wrapper>
  );
};

export default BlockWrapper;
